<div class="row">
    <div class="col s12">
        <div class="container">
            <div id="login-page" class="row">
                <div class="col s12 m6 l4 z-depth-4 card-panel border-radius-6 login-card bg-opacity-8">
                    <div class="row">
                        <div class="input-field col s12" style="text-align: center; padding-top: 5px; font-weight: bold; font-size: 16px;">
                            Giải pháp toàn diện về quản lý và phân tích
                            dữ liệu bản đồ không gian                              
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col s12" style="text-align: center; padding-top: 5px;">
                            <img width="300" height="107" src="/assets/image/logo.png"
                                title="Cổng xác thực để truy cập hệ sinh thái số"
                                alt="Cổng xác thực để truy cập hệ sinh thái số">
                        </div>
                    </div>
                    <div class="row margin">
                        <div class="input-field col s12">
                            <h5>Khôi phục mật khẩu thành công.</h5>
                            <h6>Vui lòng kiểm tra email đăng ký, xác nhận vào đường dẫn để khôi phục mật khẩu.</h6>
                        </div>
                    </div>
                    <div class="row margin">
                        <div class="input-field col s12">
                            <h5>Password recovery successful.</h5>
                            <h6>Please check your registration email and confirm the link to recover your password.</h6>
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col s6 m6 l6">
                            <p class="margin medium-small">
                                <a href="{{domainName}}Login"
                                    title="{{DownloadService.TitleDangNhap}}">{{DownloadService.TitleDangNhap}}</a>
                            </p>
                        </div>
                        <div class="input-field col s6 m6 l6">
                            <p class="margin right-align medium-small">
                                <a href="{{domainName}}Register"
                                    title="{{DownloadService.TitleDangKy}}">{{DownloadService.TitleDangKy}}</a>
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col s12 m12 l12">
                            <p class="margin medium-small" style="text-align: center; font-weight: bold;">Công Ty Cổ Phần Đầu Tư Digital Kingdom
                            </p>
                        </div>                           
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>